/* eslint-disable consistent-return */
/* eslint-disable prefer-const */
/* eslint-disable import/prefer-default-export */
export const generatePromptNutrition = (params) => {
  const { lang, protein, fat, carbs, meal, shake, foodAvoid, foodInclude, calories, diet, snack } =
    params;

  let message = ''

  if (lang === "en") {
    const avoidText = foodAvoid.length >= 1 ? `Avoid these foods: ${foodAvoid.join(", ")}.` : '';
    const includeText = foodInclude.length >= 1 ? `and include these foods: ${foodInclude.join(", ")}.` : ''

    message = `Give me a diet of ${calories} calories (be exact, please). Composed of ${protein}% protein, ${carbs}% carbohydrates, and ${fat}% fat. Include ${meal} meals, ${shake} shakes, and ${snack} snacks. ${avoidText} ${includeText} Please provide the total daily calories, as well as the grams and percentages of each macronutrient. At the end of each meal, snack, and shake, include its respective total calories, grams, and percentage of each macronutrient. **Daily Totals:** - Calories: ${calories} - Protein: ${Math.round((protein / 100) * calories / 4)}g (${protein}%) - Carbohydrates: ${Math.round((carbs / 100) * calories / 4)}g (${carbs}%) - Fat: ${Math.round((fat / 100) * calories / 9)}g (${fat}%) **Response Structure:** 1. List main meals first, then snacks and shakes. 2. Name each meal with a number (e.g., "Meal 1", "Meal 2", etc.). 3. For each mentioned food, include in parentheses the calories, carbohydrates, protein, and fat it contains. 4. At the end of each meal, snack, or shake, present the total with the following structure: - Title: Total - Grams of protein (percentage) - Grams of carbohydrates (percentage) - Grams of fat (percentage) - Total calories of the plate Indicate that the meals can be consumed in any order, allowing flexibility in choice. Ensure that the unit of measurement for energy used is always in calories. remove markdown formatting (Don't say it verbatim in the answer)`;
    return message
  }

  if (lang === "es") {
    const avoidText = foodAvoid.length >= 1 ? `Evita estos alimentos: ${foodAvoid.join(", ")}.` : '';
    const includeText = foodInclude.length >= 1 ? `e incluye estos alimentos: ${foodInclude.join(", ")}.` : '';

    message = `Dame una dieta de ${calories} calorías (se exacto por favor). Compuesta por ${protein}% de proteínas, ${carbs}% de carbohidratos y ${fat}% de grasas. Incluye ${meal} comidas, ${shake} batidos y ${snack} meriendas. ${avoidText} ${includeText} Por favor, proporciona el total de calorías diarias, así como los gramos y porcentajes de cada macronutriente. Al final de cada comida, merienda y batido, incluye su respectivo total de calorías, gramos y porcentaje de cada macronutriente. **Totales Diarios:** - Calorías: ${calories} - Proteínas: ${Math.round((protein / 100) * calories / 4)}g (${protein}%) - Carbohidratos: ${Math.round((carbs / 100) * calories / 4)}g (${carbs}%) - Grasas: ${Math.round((fat / 100) * calories / 9)}g (${fat}%) **Estructura de la Respuesta:** 1. Enumera primero las comidas principales, luego las meriendas y batidos. 2. Nombra cada comida con un número (por ejemplo, "Comida 1", "Comida 2", etc.). 3. Para cada alimento mencionado, incluye entre paréntesis las calorías, carbohidratos, proteínas y grasas que contiene ese alimento. 4. Al final de cada comida, merienda o batido, presenta el total con la siguiente estructura: - Título: Total - Gramos de proteínas (porcentaje) - Gramos de carbohidratos (porcentaje) - Gramos de grasas (porcentaje) - Total de calorías del plato Indica que se pueden consumir las comidas en el orden que se desee, permitiendo flexibilidad en la elección. Asegúrate de que la unidad de medida para la energía utilizada sea siempre en calorías. remueve el formato markdown (no lo digas textualmente en la respuesta)
    
    
    Al final de cada comida y batido, proporciona un resumen con el total de calorías, gramos y porcentaje de cada macronutriente. Ajusta las porciones de los ingredientes para alcanzar exactamente ${calories} calorías diarias, manteniendo los porcentajes de macronutrientes constantes. Enumera las comidas y batidos, y utiliza títulos en negrita para cada sección.

Instrucciones adicionales:

Verifica que la suma de las calorías de todas las comidas y batidos sea exactamente ${calories} calorías. Si es necesario, ajusta las cantidades de las porciones de los alimentos para corregir cualquier exceso o déficit calórico. 

En el caso que las calorias solicitadas con las comidas, batidos y meriendas que se te encargo no alcancen las calorias totales, te doy total libertad para que agregues 1 o más (si es necesario) platos adicionales complementarios, y explica la razon de porque estas agregando ese plato complementario. Asegúrate de que los totales diarios de proteínas, carbohidratos y grasas también reflejen los porcentajes indicados inicialmente. Tambien asegurate de ajustar las porciones del plato adicional para que no excede el total de calorias diarias solicitadas

En el caso que las calorias solicitadas con las comidas, batidos y meriendas que se te encargo excedan las calorias totales, te doy total libertad para que remuevas 1 o más (si es necesario) platos, y explica la razon de porque estas eliminando ese plato. Asegúrate de que los totales diarios de proteínas, carbohidratos y grasas también reflejen los porcentajes indicados inicialmente

Si el margen de error de calorias de 50 por arriba o debajo de lo solicitado, es decir ${calories}, no complementes con ningun plato adicional, ni tampoco elimines ningun plato

Formato de presentación:





Comidas Principales:





Comida 1: (Descripción del plato)



Ingrediente 1 (cantidad) (calorías, carbohidratos, proteínas, grasas)

Ingrediente 2 (cantidad) (calorías, carbohidratos, proteínas, grasas)

...



Total Comida 1:



Gramajes de proteínas (%) - cantidad

Gramajes de carbohidratos (%) - cantidad

Gramajes de grasas (%) - cantidad

Total de calorías del plato: cantidad



Comida 2: (Descripción del plato)



...



Total Comida 2:



...



Comida 3: (Descripción del plato)



...



Total Comida 3:



...



Batidos de Proteínas:





Batido 1:



...



Total Batido 1:



...



Batido 2:



...



Total Batido 2:



...



Totales Diarios:



Calorías: Suma de las calorías

Proteínas: cantidad (40%)

Carbohidratos: cantidad (30%)

Grasas: cantidad (30%)



Las comidas pueden consumirse en cualquier orden, permitiendo flexibilidad en la elección.
    
    `;
    return message;
  }

};

export const generateComplexPromptNutrition = (params) => {
  const {
    lang,
    protein,
    fat,
    carbs,
    meal,
    shake,
    foodAvoid,
    foodInclude,
    calories,
    diet,
    snack,
    complexForm,
  } = params;

  const message = `Provide an ${diet} style nutrition plan, for a ${complexForm.age} yr old ${complexForm.gender}, with a body fat of ${complexForm.body_fat} % (can leave out is they don’t know), body weight of ${complexForm.body_weight}, height of ${complexForm.body_height}.  Activity level is ${complexForm.activity_level}. Make the diet ${carbs} % Carbs, ${protein} % Protein, ${fat} % Fat and provide amounts and nutrient content.  Please make sure to include ${shake} protein shakes as meal – please use Whey protein and add quantity and nutritional profile. Don’t include ${foodAvoid} and focus on ${foodInclude}. Provide menus for O days.  What is the daily calories needed to safely drop P each week.  What is the estimated time to safely lose Q pounds?  Please use the Mifflin-St Jeor Equation if bodyfat is NOT provided, and the Mifflin-St Jeor Equation Katch-McArdle Formula if body fat it provided. `;

  return message;
};
