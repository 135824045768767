/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Menu as MenuLang,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  Avatar,
  Text,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Flex,
  useToast,
  Divider,
  AbsoluteCenter,
  Input,
  IconButton,
  Badge,
} from "@chakra-ui/react";
import InfoIcon from "@mui/icons-material/Info";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import languages from "utils/languages";
import QRCode from "react-qr-code";
import i18next from "i18next";
import { getNutritionShared } from "services/nutrition";

import UsaLogo from "assets/eeuu.png";
import SpainLogo from "assets/spain.png";
import useLang from "hooks/useLang";
import logo from "../../../assets/3.png";
import styles from "./showNutrition.module.scss";

const SharedNutrition = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { id } = useParams();
  const currentYear = new Date().getFullYear();
  const [data, setData] = useState({});
  const { t, lang } = useLang();
  const toast = useToast();

  const onLanguageChange = (code) => i18next.changeLanguage(code);

  const setDirection = (direction) => {
    const string = [];
    string.push(`${direction?.street1}, `);
    if (direction?.street2) string.push(`${direction?.street2}, \n`);
    string.push(`${direction?.city}, ${direction?.state} ${direction?.zip}`);
    return string;
  };


  const onGetNutrition = async () => {
    try {
      const response = await getNutritionShared(id);
      if (response.data) throw new Error("something went wrong");
      setData(response.nutrition);
    } catch (err) {
      console.log(err);
    }
  };

  const copyClipboard = () => {
    const copyText = document.getElementById("nutrition-url");

    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices

    navigator.clipboard.writeText(copyText.value);
    toast({
      position: "bottom",
      render: () => (
        <Box color="white" p={3} bg="black" textAlign="center">
          {t("copiedClipboard")}
        </Box>
      ),
    });
  };

  useEffect(() => {
    onGetNutrition();
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div onClick={onOpen}>
          <InfoIcon />
        </div>
        <img src={logo} alt="logo" className={styles.logo} />
        <MenuLang>
          <MenuButton as={Button} variant="ghost" fontSize="40px" padding={0} borderRadius="full">
            <Box display="flex" justifyContent="center" alignItems="center">
              <Avatar
                name="english lang"
                src={lang === "en" ? UsaLogo : SpainLogo}
                width="30px"
                height="30px"
              />
            </Box>
          </MenuButton>
          <MenuList zIndex="101">
            {languages.map(({ code, name, countryCode }) => (
              <MenuItem key={code} color="ptText.500" onClick={() => onLanguageChange(code)}>
                <span className={`flag-icon flag-icon-${countryCode}`} />
                <span>{name}</span>
              </MenuItem>
            ))}
          </MenuList>
        </MenuLang>
      </div>

      {data?.id_client?.status === "inactive" && (
        <div className={styles.content}>
          <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" gridGap={4}>
            <Text textAlign="center" fontSize="2xl" fontWeight="bold">
              {t('title_disable_link')}
            </Text>
            <Text textAlign="center">
              {t('message_disable_link')}
            </Text>
          </Box>
        </div>
      )}

      {data.id_client?.status !== "inactive" && (
        <Flex justifyContent="center" alignItems="center" flexDirection="column" px={3}>
          <Heading size="md" textAlign="center">{data.name}</Heading>

          <Badge bg="#b42519" color="white">
            {t(data.goals)}
          </Badge>
          <Text fontSize="12px" my={4}>
            <div dangerouslySetInnerHTML={{ __html: data.diet }} />
          </Text>
        </Flex>
      )}
      <div className={styles.footer}>
        <div className={styles.term}>
          <a href="#a">{t("privacy_policy")}</a>
          <a href="#a">{t("terms_use")}</a>
          <a href="#a">Legal</a>
        </div>
        <span>
          Copyright © {currentYear} The Institute Of Human Performance | {t("copyright_text")}
        </span>
      </div>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton
              style={{
                backgroundColor: "white",
                top: "0px",
                right: "0px",
                borderRadius: "50%",
              }}
            />
            <ModalBody my={4}>
              <div className={styles.usersInfo}>
                <div>
                  <div className={styles.trainerInfo}>
                    <Avatar
                      size="lg"
                      name={data.id_trainer?.name || "PT"}
                      src={`${process.env.REACT_APP_FILE_API_URL}/file?file=${data.id_trainer?.pic !== "" ? data.id_trainer?.pic : data.id_trainer?.logo
                        }`}
                    />
                    <div>
                      <span className={styles.badge}>Trainer</span>
                      <Heading size="md" as="h4">
                        {data.id_trainer?.name || ""}
                      </Heading>
                      <Text>{t(data.id_trainer?.speciality || "")}</Text>
                    </div>
                  </div>
                  <Text textAlign="center" fontSize="12px" color="gray">
                    {data.id_trainer?.phone}
                  </Text>
                  <Text textAlign="center" fontSize="12px" color="gray">
                    {setDirection(data.id_trainer?.address)}
                  </Text>
                </div>
                <Box position="relative" my={8}>
                  <Divider />
                  <AbsoluteCenter fontWeight="bold" bg="white" px="4" fontSize="xs" color="gray">
                    {t("clientInfo")}
                  </AbsoluteCenter>
                </Box>
                <div>
                  <Heading textAlign="center" size="md" as="h4">
                    {data.id_client?.name}
                  </Heading>
                  <Text textAlign="center">{data.id_client?.email}</Text>
                </div>
              </div>
              <Box position="relative" my={8}>
                <Divider />
                <AbsoluteCenter fontWeight="bold" bg="white" px="4" fontSize="xs" color="gray">
                  {t("shareProgram")}
                </AbsoluteCenter>
              </Box>
              <Text textAlign="center" my={4} fontSize="xs" colorScheme="gray">
                {t("scanQr")}
              </Text>
              <div style={{ height: "auto", margin: "0 auto", maxWidth: 120, width: "100%" }}>
                <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={`${window.location.protocol}//${window.location.host}/shared-nutrition/${id}`}
                  viewBox="0 0 256 256"
                />
              </div>
              <Box position="relative" my={6}>
                <Text textAlign="center" bg="white" px="4" fontSize="xs">
                  {t("orCopyLink")}
                </Text>
              </Box>
              <Flex gridGap={4} alignItems="center">
                <Input
                  disabled
                  readOnly
                  value={`${window.location.protocol}//${window.location.host}/shared-nutrition/${id}`}
                  cursor="pointer"
                  id="nutrition-url"
                  fontSize="12px"
                  onClick={copyClipboard}
                />
                <IconButton
                  isRound
                  size="sm"
                  colorScheme="red"
                  variant="ghost"
                  onClick={copyClipboard}
                  icon={<ContentCopyIcon fontSize="inherit" />}
                />
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </div>
  );
};

export default SharedNutrition;
