/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Document,
  PDFDownloadLink,
  Page,
  View,
  Text,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { Button } from "@chakra-ui/react";
import useLang from "hooks/useLang";
import Logo from "../../../../assets/1.png";
import WorldIcons from "../../../../assets/icons/world.png";
import TopImage from "../../../../assets/headerPdf.png";

// Función para convertir HTML a componentes de @react-pdf/renderer
const htmlToPdfComponents = (html) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  const elements = [];

  tempDiv.childNodes.forEach((node) => {
    if (node.nodeType === Node.TEXT_NODE) {
      elements.push(<Text key={elements.length}>{node.textContent}</Text>);
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      if (node.tagName === "BR") {
        elements.push(<Text key={elements.length}>{"\n"}</Text>);
      } else if (node.tagName === "P") {
        elements.push(
          <Text key={elements.length} style={{ marginBottom: 10 }}>
            {node.textContent}
          </Text>,
        );
      }
    }
  });

  return elements;
};

const NutritionPdfDocument = ({ trainer, client, diet }) => {
  const { t } = useLang();
  const plainText = htmlToPdfComponents(diet.diet);
  const styles = StyleSheet.create({
    widthFull: {
      width: "100%",
    },
    headerTitleInformation: {
      fontSize: 10,
      color: "white",
      paddingLeft: 10,
      paddingVertical: 3,
      backgroundColor: "black",
      borderTop: "2px solid black",
      textTransform: "uppercase",
      borderTopLeftRadius: 15,
      borderTopRightRadius: 15,
    },
    headerInformation: {
      display: "flex",
      flexDirection: "row",
    },
    clientContainer: {
      width: "40%",
      height: 170,
      fontSize: 8,
      border: "1.5px solid black",
      borderRadius: 15,
    },
    containerFields: {
      paddingVertical: 4,
    },
    clientBox: {
      display: "flex",
      paddingHorizontal: 10,
      paddingVertical: 3,
      flexDirection: "row",
      flexWrap: "wrap",
    },
    clientLabel: {
      width: "30%",
      paddingVertical: 3,
    },
    clientInformation: {
      backgroundColor: "#f6f6f6",
      padding: 3,
      borderBottom: "1px solid #b42519",
      width: "70%",
      borderRadius: 5,
    },
    trainerContainer: {
      width: "55%",
      height: 170,
      fontSize: 8,
      border: "1.5px solid black",
      borderRadius: 15,
    },
    trainerMainBox: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    trainerBox: {
      width: "29%",
      height: 100,
      padding: 5,
      margin: "auto",
    },
    trainerBoxContactInfo: {
      width: "67%",
      height: 170,
      padding: 5,
    },
    divider: {
      height: "100%",
      width: "0.3%",
      backgroundColor: "black",
    },
    trainertBoxField: {
      display: "flex",
      paddingVertical: 2,
      flexDirection: "row",
      flexWrap: "wrap",
    },
    trainertLabel: {
      width: "32%",
      paddingVertical: 3,
    },
    trainertInformation: {
      backgroundColor: "#f6f6f6",
      padding: 3,
      borderBottom: "1px solid #b42519",
      width: "68%",
      borderRadius: 5,
    },
    logosMedia: {
      width: 10,
    },
    imageLogoTrainer: {
      position: "relative",
    },
    imagenLogo: {
      position: "absolute",
      left: 0,
      top: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      right: 0,
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 5,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "gray",
    },
    footer: {
      position: "absolute",
      width: "100%",
      bottom: 0,
      height: 40,
    },
    footerItem: {
      backgroundColor: "#383838",
      borderBottom: "3px solid #b42519",
      width: "100%",
      height: 25,
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      justifyContent: "center",
      alignItems: "center",
    },
    containerMain: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      width: "100%",
    },
    containerHeader: {
      border: "1.2px solid black",
      marginTop: 25,
      borderTopLeftRadius: 5,
      borderTopRightRadius: 15,
      borderBottomRightRadius: 15,
      borderBottomLeftRadius: 15,
      width: "100%",
    },
    headerWeek: {
      backgroundColor: "#b42519",
      color: "white",
      fontSize: 10,
      paddingVertical: 5,
      width: "50%",
      top: -1.5,
      left: -2,
      paddingLeft: 10,
      borderTopRightRadius: 25,
      borderBottomRightRadius: 25,
    },
    contaienrMapDays: {
      display: "flex",
      flexDirection: "column",
      marginHorizontal: 10,
      marginVertical: 7,
    },
    dayStyles: {
      width: "15%",
      paddingHorizontal: 6,
      paddingVertical: 4,
      borderRadius: 5,
      textAlign: "center",
      marginVertical: 5,
      fontSize: 8,
      backgroundColor: "black",
      color: "white",
    },
    noHaveObservation: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "gray",
      paddingVertical: 20,
      width: "100%",
    },
  });

  const setDirection = (direction) => {
    const string = [];
    string.push(`${direction?.street1}, `);
    if (direction?.street2) string.push(`${direction?.street2}, \n`);
    string.push(`${direction?.city}, ${direction?.state} ${direction?.zip}`);
    return string;
  };

  const chooseImageCover = () => {
    if (trainer?.showField?.pic && trainer?.showField?.logo) {
      return (
        <View style={{ display: 'flex', flexDirection: 'column' }}>
          <Image
            src={`${process.env.REACT_APP_FILE_API_URL}/file?file=${trainer?.pic}`}
            style={{ width: 50, marginBottom: 2 }}
          />
          <Image
            src={`${process.env.REACT_APP_FILE_API_URL}/file?file=${trainer?.logo}`}
            style={{ width: 50, marginTop: 2 }}
          />
        </View>
      )
    }


    if (trainer?.showField?.pic) {
      return (
        <Image
          src={`${process.env.REACT_APP_FILE_API_URL}/file?file=${trainer?.pic}`}
          style={{ width: 50 }}
        />
      )
    }

    if (trainer?.showField?.logo) {
      return (
        <Image
          src={`${process.env.REACT_APP_FILE_API_URL}/file?file=${trainer?.logo}`}
          style={{ width: 50 }}
        />
      )
    }

    if (trainer?.pic) {
      return (
        <Image
          src={`${process.env.REACT_APP_FILE_API_URL}/file?file=${trainer?.pic}`}
          style={{ width: 50 }}
        />
      )
    }

    if (trainer?.logo) {
      return (
        <Image
          src={`${process.env.REACT_APP_FILE_API_URL}/file?file=${trainer?.logo}`}
          style={{ width: 50 }}
        />
      )
    }

    return (
      <Image
        src={Logo}
        style={{ width: 50 }}
      />
    )
  }

  return (
    <Document>
      <Page size="A4" orientation="portrait" wrap={false}>
        <View style={styles.widthFull}>
          <Image src={TopImage} style={{ width: "100%" }} />
        </View>
        <View style={{ marginHorizontal: 30, marginTop: 30, marginBottom: 40 }}>
          <View style={styles.headerInformation}>
            <View style={styles.clientContainer}>
              <View style={styles.headerTitleInformation}>
                <Text>{t("client")}</Text>
              </View>
              <View style={styles.containerFields}>
                <View style={styles.clientBox}>
                  <View style={styles.clientLabel}>
                    <Text>{t("table_name")}</Text>
                  </View>
                  <View style={styles.clientInformation}>
                    <Text>
                      {`${client.name}`} {client.lastName}
                    </Text>
                  </View>
                </View>
                <View style={styles.clientBox}>
                  <View style={styles.clientLabel}>
                    <Text>{t("phonenumber")}</Text>
                  </View>
                  <View style={styles.clientInformation}>
                    <Text>{client.phone}</Text>
                  </View>
                </View>
                <View style={styles.clientBox}>
                  <View style={styles.clientLabel}>
                    <Text>{t("profile_address")}</Text>
                  </View>
                  <View style={styles.clientInformation}>
                    <Text>{setDirection(client.address)}</Text>
                  </View>
                </View>
                <View style={styles.clientBox}>
                  <View style={styles.clientLabel}>
                    <Text>Email</Text>
                  </View>
                  <View style={styles.clientInformation}>
                    <Text>{client.email}</Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={{ width: "5%" }} />
            <View style={styles.trainerContainer}>
              <View style={styles.headerTitleInformation}>
                <Text>
                  {t("trainer")} {`${trainer?.name} `} {trainer?.lastName}
                  {trainer?.showField?.speciality && `, ${t(trainer.speciality)}`}
                </Text>
              </View>
              <View style={styles.trainerMainBox}>
                <View style={styles.trainerBoxContactInfo}>
                  <View style={styles.trainertBoxField}>
                    <View style={styles.trainertLabel}>
                      <Text>Email</Text>
                    </View>
                    <View style={styles.trainertInformation}>
                      <Text>{trainer.email}</Text>
                    </View>
                  </View>
                  {trainer?.showField?.phone && (
                    <View style={styles.trainertBoxField}>
                      <View style={styles.trainertLabel}>
                        <Text>{t("phonenumber")}</Text>
                      </View>
                      <View style={styles.trainertInformation}>
                        <Text>{trainer.phone}</Text>
                      </View>
                    </View>
                  )}
                  {trainer?.showField?.address && (
                    <View style={styles.trainertBoxField}>
                      <View style={styles.trainertLabel}>
                        <Text>{t("profile_address")}</Text>
                      </View>
                      <View style={styles.trainertInformation}>
                        <Text>{setDirection(trainer.address)}</Text>
                      </View>
                    </View>
                  )}
                  {trainer?.showField?.certifications && (
                    <View style={styles.trainertBoxField}>
                      <View style={styles.trainertLabel}>
                        <Text>{t("certifications")}</Text>
                      </View>
                      <View style={styles.trainertInformation}>
                        <Text>{trainer.certifications}</Text>
                      </View>
                    </View>
                  )}
                  {trainer?.showField?.job_description && (
                    <View style={styles.trainertBoxField}>
                      <View style={styles.trainertLabel}>
                        <Text>{t("job_description")}</Text>
                      </View>
                      <View style={styles.trainertInformation}>
                        <Text>{t(trainer.job_description)}</Text>
                      </View>
                    </View>
                  )}
                </View>
                <View style={styles.divider} />
                <View style={styles.trainerBox}>
                  <View style={styles.imageLogoTrainer}>
                    <View style={styles.imagenLogo}>
                      {chooseImageCover()}
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            marginVertical: 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Text style={{ fontSize: 18 }}>{diet.name}</Text>
          <View
            style={{
              backgroundColor: "#b42519",
              paddingHorizontal: 4,
              paddingVertical: 2,
              borderRadius: 8,
              color: "white",
            }}>
            <Text style={{ fontSize: 16 }}>{t(diet.goals)}</Text>
          </View>
        </View>
        <View style={{ marginHorizontal: 30, marginTop: 30, marginBottom: 40 }}>
          <Text style={{ fontSize: 11 }}>{plainText}</Text>
        </View>
      </Page>
    </Document>
  );
};

const NutritionPdf = ({ diet, client, trainer }) => {
  const { t } = useLang();

  return (
    <PDFDownloadLink
      document={<NutritionPdfDocument diet={diet} client={client} trainer={trainer} />}
      fileName={diet.name}>
      {({ loading }) =>
        loading ? (
          <Button isLoading={loading} loadingText={t("generasting_pdf")} spinnerPlacement="start">
            {t("generasting_pdf")}
          </Button>
        ) : (
          <Button
            variant="solid"
            colorScheme="ptRed"
            leftIcon={<FileDownloadIcon fontSize="small" />}>
            {t("download")}
          </Button>
        )
      }
    </PDFDownloadLink>
  );
};

export default NutritionPdf;
