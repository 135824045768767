/* eslint-disable react/prop-types */
import { Box, Button, Input, Text } from "@chakra-ui/react";
import useLang from "hooks/useLang";
import { deleteStylesDiets } from "services/nutrition";

const DietStyles = ({
  newDiets,
  setNewDiets,
  diet,
  setDiet,
  setProtein,
  setCarbs,
  setFat,
  customDiets,
  setCustomDiets,
}) => {
  const { t } = useLang();

  const changeMacronutriets = (style) => {
    setDiet(style);
    switch (style) {
      case "Paleo":
        setProtein(40);
        setCarbs(25);
        setFat(35);
        break;
      case "Keto":
        setProtein(25);
        setCarbs(5);
        setFat(70);
        break;
      case "Vegana":
        setProtein(20);
        setCarbs(50);
        setFat(30);
        break;
      case "Mediterranea":
        setProtein(20);
        setCarbs(45);
        setFat(35);
        break;
      case "Dieta de zona":
        setProtein(30);
        setCarbs(40);
        setFat(30);
        break;
      case "Alta en proteina":
        setProtein(40);
        setCarbs(30);
        setFat(30);
        break;
      default:
        setProtein(30);
        setCarbs(40);
        setFat(30);
        break;
    }
  };

  const handleCustomDiet = (e) => {
    e.preventDefault()
    setCustomDiets([
      ...customDiets,
      {
        name: newDiets,
        protein: 30,
        carb: 40,
        fat: 30,
      },
    ]);
    setDiet(newDiets);
    setNewDiets("");
  };

  const handleDeleteCustomDiet = async (style) => {
    try {
      await deleteStylesDiets(style._id)
      const result = customDiets.filter((e) => e.name !== style.name);
      setCustomDiets([...result]);
    } catch (error) {
      console.error(error)
    }
  };

  const handleChangeStatsDiet = (style) => {
    setDiet(style.name);
    setProtein(style.protein);
    setCarbs(style.carb);
    setFat(style.fat);
  };

  return (
    <Box display="flex" flexDirection="column" p="1em" gridGap={4} bg="white">
      <Text fontSize="x-large" fontWeight="bold">
        {t("nutrition_section.diet_styles")}
      </Text>
      <Box display="flex" flexDirection="column" gridGap="1em">
        <Text>{t("diets.suggested")}</Text>
        <Box display="flex" gridGap={3} flexWrap="wrap">
          <Button
            variant="solid"
            bg={diet === "Paleo" ? "#b42519" : "#f6f6f6"}
            color={diet === "Paleo" ? "white" : "gray"}
            onClick={() => changeMacronutriets("Paleo")}>
            {t("diets.paleo")}
          </Button>

          <Button
            bg={diet === "Keto" ? "#b42519" : "#f6f6f6"}
            variant="solid"
            color={diet === "Keto" ? "white" : "gray"}
            onClick={() => changeMacronutriets("Keto")}>
            {t("diets.keto")}
          </Button>

          <Button
            variant="solid"
            bg={diet === "Vegana" ? "#b42519" : "#f6f6f6"}
            color={diet === "Vegana" ? "white" : "gray"}
            onClick={() => changeMacronutriets("Vegana")}>
            {t("diets.vegan")}
          </Button>

          <Button
            variant="solid"
            color={diet === "Mediterranea" ? "white" : "gray"}
            bg={diet === "Mediterranea" ? "#b42519" : "#f6f6f6"}
            onClick={() => changeMacronutriets("Mediterranea")}>
            {t("diets.mediterranean")}
          </Button>

          <Button
            variant="solid"
            bg={diet === "Dieta de zona" ? "#b42519" : "#f6f6f6"}
            color={diet === "Dieta de zona" ? "white" : "gray"}
            onClick={() => changeMacronutriets("Dieta de zona")}>
            {t("diets.zone_diet")}
          </Button>
          {/* <Button
            variant="solid"
            bg={diet === "Alta en proteina" ? "#b42519" : "#f6f6f6"}
            color={diet === "Alta en proteina" ? "white" : "gray"}
            onClick={() => changeMacronutriets("Alta en proteina")}>
            {t("diets.high_protein")}
          </Button> */}
        </Box>
        <hr />
        {customDiets.length >= 1 && (
          <Box>
            <Text>{t('diets.latest_added')}</Text>
            <Box display="flex" flexWrap="wrap" gridGap={2}>
              {customDiets.map((dietstyle) => (
                <Button
                  variant="solid"
                  bg={diet === dietstyle.name ? "#b42519" : "#f6f6f6"}
                  color={diet === dietstyle.name ? "white" : "gray"}
                  justifyContent="center"
                  gridGap={2}
                  display="flex"
                  alignItems="center"
                  _hover={{ color: `${diet === dietstyle.name ? "white" : "gray"}` }}
                  onClick={() => handleChangeStatsDiet(dietstyle)}>
                  <span>{dietstyle.name}</span>
                  <Button
                    size="xs"
                    bg="transparent"
                    onClick={() => handleDeleteCustomDiet(dietstyle)}>
                    x
                  </Button>
                </Button>
              ))}
            </Box>
          </Box>
        )}

        <form onSubmit={handleCustomDiet}>
          <Box display="flex" gridGap={3}>
            <Input
              placeholder={t("diets.enter_name_diet")}
              border="1px solid black"
              value={newDiets}
              onChange={(e) => setNewDiets(e.target.value)}
            />
            <Button bg="black" onClick={handleCustomDiet} color="white">
              {t("add")}
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default DietStyles;
